import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { DriverOnboardingActions, DriverOnboardingSelectors } from '../../store';

@Component({
    selector: 'app-onboarding-elearning-cooldown',
    templateUrl: 'onboarding-elearning-cooldown.component.html',
    styleUrls: ['onboarding-elearning-cooldown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OnboardingElearningCooldownComponent implements OnInit {

  driverOnboardingExamCooldownEndDate$: Observable<Date> = EMPTY;

  constructor(private store: Store) { }

  ngOnInit() {
    this.driverOnboardingExamCooldownEndDate$ = this.store.select(DriverOnboardingSelectors.selectDriverOnboardingExampleCooldownEndDate);
  }

  onEditClick() {
    this.store.dispatch(DriverOnboardingActions.onboardingEditProfileClicked());
  }

  onLogoutClick() {
    this.store.dispatch(DriverOnboardingActions.logoutButtonClicked());
  }
}
