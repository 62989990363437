import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, Observable, Subject, filter, map, take, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthSelectors } from '../../../../auth/store';
import { DriverOnboardingActions, DriverOnboardingSelectors } from '../../store';

@Component({
    selector: 'app-onboarding-introduction',
    templateUrl: 'onboarding-introduction.component.html',
    styleUrls: ['onboarding-introduction.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OnboardingIntroductionComponent implements OnInit, OnDestroy {

  iframeSrc$: Observable<SafeResourceUrl> = EMPTY;
  destroyed$ = new Subject<void>();
  loading$ = new BehaviorSubject(false);

  constructor(
    private store: Store,
    private sanitize: DomSanitizer
  ) { }

  ngOnInit() {
    this.iframeSrc$ = this.store.select(AuthSelectors.selectUserId).pipe(
      map(userId =>
        this.sanitize.bypassSecurityTrustResourceUrl(`https://www.videoask.com/${environment.videoAskVideoId}#user_id=${userId}`)
      )
    );
    const isVideoaskFormSubmitted = (message: MessageEvent) =>
      message.origin === "https://www.videoask.com" &&
      message.data &&
      message.data.type &&
      message.data.type.startsWith("videoask_submitted")

    window.addEventListener("message", message => {
      if (isVideoaskFormSubmitted(message)) {
        this.loading$.next(true);
      }
    });

    this.store.select(DriverOnboardingSelectors.selectIntroductionVideoUrl).pipe(
      filter(url => !!url),
      take(1),
      takeUntil(this.destroyed$)
    ).subscribe(() => this.store.dispatch(DriverOnboardingActions.introductionVideoUploaded()));
  }

  onLogoutClick() {
    this.store.dispatch(DriverOnboardingActions.logoutButtonClicked());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
