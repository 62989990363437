import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, Subject, filter, map, takeUntil } from 'rxjs';
import { Area } from 'src/app/core/models/firestore.model';
import { DriverOnboardingVM } from '../../models/driver-onboarding.vm';
import { DriverOnboardingActions, DriverOnboardingSelectors } from '../../store';

@Component({
    selector: 'app-onboarding-personal-details',
    templateUrl: 'onboarding-personal-details.component.html',
    styleUrls: ['onboarding-personal-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OnboardingPersonalDetailsComponent implements OnInit, OnDestroy {

  areas$: Observable<Area[]> = EMPTY;
  form = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required, Validators.pattern('^\\+\\d{9,16}$')]),
  });
  destroyed$ = new Subject<void>();
  savingPersonalInfo$: Observable<boolean> = EMPTY;

  constructor(private store: Store) { }

  ngOnInit() {
    this.savingPersonalInfo$ = this.store.select(DriverOnboardingSelectors.selectIsSavingOnboardingPersonalInfo);
    this.store.select(DriverOnboardingSelectors.selectDriverOnboardingVM).pipe(
      takeUntil(this.destroyed$),
      filter(driverOnboarding => !!driverOnboarding),
      map(driverOnboarding => <DriverOnboardingVM>driverOnboarding),
    ).subscribe(driverOnboarding => {
      this.form.controls.firstName.setValue(driverOnboarding.firstName);
      this.form.controls.lastName.setValue(driverOnboarding.lastName);
      this.form.controls.phoneNumber.setValue(driverOnboarding.phoneNumber);
    });
  }

  hasError(controlName: string, errorName: string) {
    return this.form.get(controlName)?.hasError(errorName);
  }

  onContinueClick() {
    this.store.dispatch(DriverOnboardingActions.personalDetailsContinueClicked({
      firstName: this.form.controls.firstName.value || '',
      lastName: this.form.controls.lastName.value || '',
      phoneNumber: this.form.controls.phoneNumber.value?.trim() || ''
    }));
  }

  onLogoutClick() {
    this.store.dispatch(DriverOnboardingActions.logoutButtonClicked());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
