
import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NotificationService } from 'src/app/core/services';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatProgressSpinnerModule]
})
export class ImageUploadComponent {
  fileType = input<string>('image/png, image/jpeg');
  isLoading = input<boolean | null>(null);
  uploadText = input<string>('');
  imgUrl = input<string | null>(null);
  fileName = input<string | null>(null);

  fileUploaded = output<string>();

  notificationService = inject(NotificationService);

  async onAddFile(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!target.files || !target.files[0]) return;

    const file = target.files[0];

    if (this.fileType() === 'application/pdf') {
      const isPdf = await this.isPdfFile(file)
      if (!isPdf) {
        this.notificationService.error('Only PDF files are allowed');
        target.value = '';
        return;
      }
      this.readFile(file);
    } else {
      this.readFile(file);
    }
  }

  private async isPdfFile(file: File): Promise<boolean> {
    if (file.type !== 'application/pdf') {
      return false;
    }
    const arr = new Uint8Array(await file.slice(0, 5).arrayBuffer());
    const header = String.fromCharCode.apply(null, Array.from(arr));
    return header === '%PDF-';
  }

  private readFile(file: File): void {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      this.fileUploaded.emit(fileReader.result as string);
    };
  }
}
