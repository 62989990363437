import { NgOptimizedImage } from '@angular/common';
import { Component, computed, DestroyRef, inject, OnInit, Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { ImageUploadComponent } from 'src/app/shared/components/image-upload/image-upload.component';
import { DriverOnboardingActions, DriverOnboardingSelectors } from '../../store';

@Component({
    selector: 'app-onboarding-driver-license',
    imports: [
        MatProgressBarModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule,
        ImageUploadComponent,
        MatDatepickerModule,
        MatInputModule,
        MatButtonModule,
        NgOptimizedImage
    ],
    templateUrl: './onboarding-driver-license.component.html',
    styleUrl: './onboarding-driver-license.component.scss'
})
export class OnboardingDriverLicenseComponent implements OnInit {
  store: Store;
  destroyRef: DestroyRef;
  fb: FormBuilder;
  form: FormGroup | undefined;
  minDate = new Date();
  maxDate = new Date('2099-12-31');

  uploadingDriverLicenseFront: Signal<boolean>;
  uploadingDriverLicenseBack: Signal<boolean>;
  savingDriverLicenseInfo: Signal<boolean>;
  backButtonDisabled: Signal<boolean>;

  constructor() {
    this.store = inject(Store);
    this.fb = inject(FormBuilder);
    this.uploadingDriverLicenseFront = this.store.selectSignal(DriverOnboardingSelectors.selectUploadingDriverLicenseFront);
    this.uploadingDriverLicenseBack = this.store.selectSignal(DriverOnboardingSelectors.selectUploadingDriverLicenseBack);
    this.savingDriverLicenseInfo = this.store.selectSignal(DriverOnboardingSelectors.selectSavingDriverLicenseInfo);
    this.backButtonDisabled = computed(() => this.uploadingDriverLicenseBack() || this.savingDriverLicenseInfo() || this.uploadingDriverLicenseFront());
    this.destroyRef = inject(DestroyRef);
  }

  ngOnInit() {
    this.createForm();
    this.subscribeToOnboardingStateChanges();
  }

  onLogoutClick() {
    this.store.dispatch(DriverOnboardingActions.logoutButtonClicked());
  }

  onDriverLicenseFrontUpload(imageSource: string) {
    this.store.dispatch(DriverOnboardingActions.driverLicenseFrontUploaded({ imageSource }));
  }

  onDriverLicenseBackUpload(imageSource: string) {
    this.store.dispatch(DriverOnboardingActions.driverLicenseBackUploaded({ imageSource }));
  }

  onContinueClick() {
    if (this.form?.valid) {
      this.store.dispatch(DriverOnboardingActions.driverLicenseContinueClicked({
        driverLicenseNumber: this.form?.get('driverLicenseNumber')?.value,
        driverLicenseExpiresAt: this.form?.get('driverLicenseExpiresAt')?.value?.getTime()
      }));
    }
  }

  onBackClick() {
    this.store.dispatch(DriverOnboardingActions.driverLicenseBackClicked());
  }

  private subscribeToOnboardingStateChanges() {
    this.store.select(DriverOnboardingSelectors.selectDriverOnboardingVM).pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(driverOnboarding => !!driverOnboarding)
    ).subscribe(driverOnboarding => {
      this.form?.patchValue({
        driverLicenseExpiresAt: this.form?.get('driverLicenseExpiresAt')?.value || driverOnboarding?.driverLicenseExpiresAt || '',
        driverLicenseNumber: this.form?.get('driverLicenseNumber')?.value || driverOnboarding?.driverLicenseNumber,
        driverLicenseFrontPhotoUrl: driverOnboarding?.driverLicenseFrontPhotoUrl,
        driverLicenseBackPhotoUrl: driverOnboarding?.driverLicenseBackPhotoUrl
      });
    });
  }

  private createForm() {
    this.form = this.fb.group({
      driverLicenseNumber: ['', Validators.required],
      driverLicenseExpiresAt: ['', Validators.required],
      driverLicenseFrontPhotoUrl: ['', Validators.required],
      driverLicenseBackPhotoUrl: ['', Validators.required]
    });
  }
}
